// This file contains data/code for filtering our meters. I expect there to be some customization
// between projects. But, also a lot of reuse.
import { appData } from './meter-data';
import appLogger from './logger';

const mlogger = appLogger.getLogger('filter-props');

const FILTER_TYPES = {
    SELECT_MULTI: 'Select Multi',
    BOOL: 'Bool',
};

class UniqueValuesChoices {
    constructor(key) {
        this.key = key;
        this.cachedChoices = undefined;
        // If we don't bind choices, this will end up being the filter prop object and key
        // won't be found
        this.choices = this.choices.bind(this);
    }

    choices(data) {
        const flogger = mlogger.getLogger('unique-values-choices');
        if (this.cachedChoices === undefined) {
            flogger.debug(`Choices for ${this.label} filter have not been cached. Calculating choices ...`);
            if (data === undefined) {
                flogger.error('Argument passed to choices() is undefined. We expected a list of our data. Returning empty array.');
                return [];
            }
            const uniqueChoices = new Set();
            flogger.debug('Looking for unique values for our choices');
            data.forEach((item) => {
                if (Array.isArray(item[this.key])) {
                    item[this.key].forEach((subitem) => uniqueChoices.add(subitem));
                } else if (item[this.key] === undefined) {
                    uniqueChoices.add('Not Specified');
                } else {
                    uniqueChoices.add(item[this.key]);
                }
            });
            this.cachedChoices = Array.from(uniqueChoices).sort();
        }
        return this.cachedChoices;
    }
}

function defaultValueAll(choices) {
    const flogger = mlogger.getLogger('default-value-all');
    flogger.debug(`Calculating default value from ${choices}`);
    if (choices === undefined) {
        flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
        return undefined;
    }
    return choices.map((choice) => choice.value || choice);
}

function standardSelectFilter(key, label) {
    return {
        label: label || key,
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices(key).choices,
        defaultValue: defaultValueAll,
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter[key] || 'Not Specified');
        },
    };
}

// function standardSelectMultiFilter(key, label) {
//     return {
//         label: label || key,
//         type: FILTER_TYPES.SELECT_MULTI,
//         choices: new UniqueValuesChoices(key).choices,
//         defaultValue: defaultValueAll,
//         filter: function filter(meter, selectedValues) {
//             let meterVal = meter[key];
//             if (!Array.isArray(meterVal) || meterVal.length === 0) {
//                 meterVal = ['Not Specified'];
//             }
//             for (let i = 0; i < meterVal.length; i++) {
//                 if (selectedValues.includes(meterVal[i])) {
//                     return false;
//                 }
//             }
//             return true;
//         },
//     };
// }

function standardBoolFilter(key, label) {
    return {
        label: label || key,
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter[key] || false);
        },
    };
}

const props = [
    {
        label: 'Routes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: new UniqueValuesChoices('Route Name').choices,
        defaultValue: function defaultValue(choices) {
            const flogger = mlogger.getLogger('routes-default');
            if (choices === undefined) {
                flogger.error('We expected choices to be given. Without it, we can\'t calculate our default value.');
                return undefined;
            }
            return choices.filter((choice) => !['Test Meters', 'NOT IN SERVICE'].includes(choice));
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(meter['Route Name']);
        },

    },
    standardSelectFilter('Meter Size'),
    standardSelectFilter('Outgoing Meter Manufacturer'),
    standardSelectFilter('Meter Type'),
    standardSelectFilter('Install Date'),
    standardBoolFilter('Town Attention Needed'),
    standardBoolFilter('Completed'),
    {
        label: 'Install Type',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        defaultValue: function defaultValue() {
            return [
                'Standard Meter Replacement',
                'Hourly Rate',
            ];
        },
        filter: function filter(meter, selectedValues) {
            // To determine the type of install, we have to look at the crew members and see if
            // they have any hours.
            const crew = meter['Crew Members'];
            if (crew === undefined || !Array.isArray(crew) || crew.length === 0
                || crew[0].Hours === undefined) {
                // This meter is a standard meter replacement
                return !selectedValues.includes('Standard Meter Replacement');
            }
            for (let i = 0; i < crew.length; i++) {
                if (crew[i].Hours !== undefined && crew[i].Hours > 0) {
                    // This meter is an hourly rate
                    return !selectedValues.includes('Hourly Rate');
                }
            }
            // If we got this far, we didn't find any hours. This meter is a standard meter
            // replacement.
            return !selectedValues.includes('Standard Meter Replacement');
        },
    },
    {
        label: 'Unsaved Changes',
        type: FILTER_TYPES.SELECT_MULTI,
        choices: function choices() {
            return [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ];
        },
        defaultValue: function defaultValue() {
            return [true, false];
        },
        filter: function filter(meter, selectedValues) {
            return !selectedValues.includes(
                appData.modifiedMeters[meter.ID] !== undefined,
            );
        },
    },
    // {
    //     label: 'In Export',
    //     type: FILTER_TYPES.SELECT_MULTI,
    //     choices: function choices() {
    //         // Gather all the export values. Alternatively, we could call /api/exports to
    //         // get a list.
    //         if (!this.cachedExportIds) {
    //             const exportIds = new Set();
    //             for (let i = 0; i < appData.meters.length; i++) {
    //                 const exports = appData.meters[i].Exports;
    //                 if (exports === undefined || !Array.isArray(exports)
    //                     || exports.length === 0) {
    //                     continue;
    //                 }
    //                 for (let j = 0; j < exports.length; j++) {
    //                     exportIds.add(exports[j]);
    //                 }
    //             }
    //             this.cachedExportIds = Array.from(exportIds);
    //             this.cachedExportIds.push('Not Exported');
    //         }
    //         return this.cachedExportIds;
    //     },
    //     defaultValue: function defaultValue(choices) {
    //         return [...choices];
    //     },
    //     filter: function filter(meter, selectedValues) {
    //         const exports = meter.Exports;
    //         if (exports === undefined || !Array.isArray(exports) || exports.length === 0) {
    //             return !selectedValues.includes('Not Exported');
    //         }
    //         for (let i = 0; i < exports.length; i++) {
    //             if (selectedValues.includes(exports[i])) {
    //                 return false;
    //             }
    //         }
    //         return true;
    //     },
    // },
];

export { FILTER_TYPES, props };
