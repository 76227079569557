<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div>
          <div>
            {{routeName}} - {{percentComplete}}&percnt; ({{numComplete}} of {{numInstallable}})
            Complete
          </div>
          <div style="width: 90%; padding-top: 5px">
            <v-progress-linear v-bind:value="percentComplete"></v-progress-linear>
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
      <!-- <v-data-table :items="meters" :headers="headers" :search="search" :loading="loading"
          hide-default-footer :mobile-breakpoint="0" disable-pagination :custom-filter="filterRow">
      -->
      <v-data-table :items="meters" :headers="headers" :search="search" :loading="loading"
          :mobile-breakpoint="0" :custom-filter="filterRow" :footer-props="{
            'items-per-page-options': [ 10, 50, 100, 500, -1 ]
          }" :items-per-page="50">
        <template v-slot:item="props">
          <tr :id="props.item.ID" class="d-block d-sm-none"
              v-if="$vuetify.breakpoint.name === 'xs'
                && (showCompleted || !props.item.Completed)" style="cursor: pointer"
              v-on:click="goToMeterDetails(props.item['ID'])">
            <!-- TODO Fix to look for uninstallable -->
            <td>
              <ul class="flex-content">
                <li class="flex-item" data-label="Account Number">
                  {{ props.item['Account Number'] }}
                </li>
                <li class="flex-item" data-label="Install Type">
                  {{ props.item['Install Type'] }}
                </li>
                <li class="flex-item" data-label="Meter Size">{{ props.item['Meter Size'] }}</li>
                <li class="flex-item" data-label="Service Address">
                  {{ props.item['Service Address'] }}
                </li>
                <li v-if="showCompleted" class="flex-item" data-label="Completed">
                  <v-icon v-if="props.item.Completed">mdi-check</v-icon>
                </li>
              </ul>
            </td>
          </tr>
          <tr :id="props.item.ID" class="d-none d-sm-table-row"
              v-if="$vuetify.breakpoint.name !== 'xs'
                && (showCompleted || !props.item.Completed)
                && (includeUninstallable || !props.item.Uninstallable)"
              style="cursor: pointer" v-on:click="goToMeterDetails(props.item['ID'])">
            <td v-for="header in props.headers" :key="header.value"
                :style="{ 'text-align': header.align }">
              <span v-if="header.value === 'Completed'">
                <v-icon v-if="props.item.Completed">
                  mdi-check
                </v-icon>
              </span>
              <span v-else>{{ props.item[header.value] }}</span>
            </td>
          </tr>
        </template>
        <template v-slot:no-results>
          No meters found
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel></v-expansion-panels>
</template>

<script>
import { updateCompletedFlag } from '../meter-data';
import appLogger from '../logger';

const moduleLogger = appLogger.getLogger('expandable-route-list');

export default {
    props: {
        routeName: String,
        meters: Array,
        headers: Array,
        search: String,
        loading: Boolean,
        showCompleted: Boolean,
        includeUninstallable: Boolean,
    },
    data: function data() {
        return {
            percentComplete: 0,
            numComplete: 0,
            numInstallable: 0,
        };
    },
    watch: {
        meters: {
            handler: function metersUpdated() {
                const flogger = moduleLogger.getLogger('metersUpdated');
                flogger.debug(`Meters in route ${this.routeName} updated. Updating percent complete ...`);
                this.updatePercentComplete();
                flogger.debugall('Done.');
            },
            deep: true,
        },
        includeUninstallable: function includeUninstallableChanged() {
            this.updatePercentComplete();
        },
    },
    methods: {
        updatePercentComplete: function updatePercentComplete() {
            const flogger = moduleLogger.getLogger('updatePercentComplete');
            flogger.debug(`Calculating percent complete for route ${this.routeName}`);
            let numComplete = 0;
            let numInstallable = 0;
            for (let i = 0; i < this.meters.length; i++) {
                updateCompletedFlag(this.meters[i]);
                if (!this.includeUninstallable && this.meters[i].Uninstallable === true) {
                    // If we are NOT including uninstallable meters AND this meter is
                    // uninstallable, don't include it in our percent complete.
                    continue;
                }
                numInstallable++;
                if (this.meters[i].Completed) {
                    numComplete++;
                }
            }
            flogger.debug(`${numComplete} of ${numInstallable} are complete`);
            if (numInstallable === 0) {
                flogger.debug('No installable meters. Setting percent complete to 0.');
                this.percentComplete = 0;
                this.numComplete = 0;
                this.numInstallable = 0;
            } else {
                this.percentComplete = Math.round((numComplete / numInstallable) * 100);
                this.numComplete = numComplete;
                this.numInstallable = numInstallable;
            }
            flogger.debug(`Updated percent complete to be ${this.percentComplete}`);
        },
        goToMeterDetails: function goToMeterDetails(meterId) {
            this.$router.push(`/meters/${meterId}`);
        },
        filterRow: function filterRow(value, search, item) {
            return value != null
              && search != null
              && JSON.stringify(item).toLowerCase().includes(search.toLowerCase());
        },
    },
    created: function created() {
        this.updatePercentComplete();
    },
    name: 'expandable-route-list',
};
</script>
